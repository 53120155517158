import { React } from 'react';
import './MiddleSection.css';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import findjpg from './StartupImages/search.png';
import connectjpg from './StartupImages/network.png';
import sharejpg from './StartupImages/communication.png';
import Button from '@mui/material/Button';
import { HashLink as Link } from 'react-router-hash-link';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea, CardActions } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

const Actioncard = ({Name, content, image, location}) => {
    return (
      <Card sx={{ maxWidth: 250}} align="center">
        <CardActionArea>
          <CardMedia component="img" height="140" image={image} alt="cardImage" 
            sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}/>
          <CardContent>
            <Typography gutterBottom variant="h5" component="div">
              {Name}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {content}
            </Typography>
          </CardContent>
        </CardActionArea>
        <CardActions className='more-info-button'>
          <Link to={location} style={{textDecoration: 'None'}} smooth >
            <Button variant="contained" sx={{background: 'black'}} size="small" color="primary"> More Info </Button>
          </Link>
        </CardActions>
      </Card>
    );
}

const MiddleSection = () => {
    return (
        <div className='middle-section'>
            <ThemeProvider theme={theme}>
                <Container maxWidth="md">
                    <Typography sx={{mt: 8}} variant="h2" align="center" color="text.primary" paragraph > Our Mission </Typography>
                    <Typography variant="h5" align="center" color="text.primary" paragraph >
                    Uncover hidden destinations and enrich travel experiences by empowering travelers with insider knowledge and tips. 
                    We believe in the beauty of lesser-known places and strive to help travelers explore and make the most of their travels.
                    </Typography>
                </ Container>
                <div className='cards'>
                    <Actioncard Name="Find" content="unique experiences on our app that can't be found anywhere else" image={findjpg} location="#find"/>
                    <Actioncard Name="Connect" content="with other adventure seekers like you" image={connectjpg} location="#connect"/>
                    <Actioncard Name="Share" content="your experiences with the rest of the community" image={sharejpg} location="#share"/>
                </div>
            </ThemeProvider>
            <hr className='line' width="90%"/>
        </div>
    );
}

export { MiddleSection };
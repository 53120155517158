import { React } from 'react';
import './ConnectSection.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { HashLink as Link } from 'react-router-hash-link';
import appscreen3 from './StartupImages/otherprofilescreen.jpg';
import appscreen4 from './StartupImages/chatscreen.jpg';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

theme.typography.h3 = {
    fontSize: "45px",
   '@media (max-width:450px)': {
     fontSize: "30px",
   },
};

const ConnectSection = () => {
    return (
        <div className='connect' id='connect'>
            <ThemeProvider theme={theme}>
                <div className='connect-text'>
                    <Typography sx={{marginTop:"20%"}} variant="h4" > Connect </Typography>
                    <Typography gutterBottom variant="h3" > With People Nearby </Typography>
                    <hr width="8%" className='line' align="left"/>
                    <Typography gutterBottom variant="h7" > 
                    Find like-minded travelers and explore new destinations together.  
                    Stay connected with travelers, even when you're on the go.        
                    </Typography>
                    <List
                    sx = {{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    }, marginLeft: "5%"
                    }}>
                        <ListItem> Find others in you're area using our proximity chat feature </ListItem>
                        <ListItem> Add new friends in cities that you travel to </ListItem>
                    </List>
                    <Link to='#signup' style={{textDecoration: 'None'}} smooth>
                        <Button onClick="" variant="contained" sx={{background: 'black', marginTop: "3%"}} size="large" > Download </Button>
                    </Link>
                </div>
                <div className='connect-screen'>
                    <img src={appscreen3} className='screens3' alt='screen3' />
                    <img src={appscreen4} className='screens4' alt='screen4' />
                </div>
            </ThemeProvider>
        </div>
    );
}

export { ConnectSection };
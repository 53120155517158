import { React } from 'react';
import './ShareSection.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { HashLink as Link } from 'react-router-hash-link';
import appscreen5 from './StartupImages/activitysection.png';
import appscreen6 from './StartupImages/profilescreen.png';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

theme.typography.h3 = {
    fontSize: "45px",
   '@media (max-width:450px)': {
     fontSize: "30px",
   },
 };

const ShareScreen = () => {
    return (
        <div className='share' id='share'>
            <ThemeProvider theme={theme}>
                <div className='share-screen'>
                    <img src={appscreen5} className='screens2' alt='screen5' />
                    <img src={appscreen6} className='screens2' alt='screen6' />
                </div>
                <div className='share-text'>
                    <Typography sx={{marginTop:"10%"}} variant="h4" > Share </Typography>
                    <Typography gutterBottom variant="h3" > Personal Experiences </Typography>
                    <hr width="8%" className='line' align="left"/>
                    <Typography gutterBottom variant="h7" > 
                    Join our community of travelers, share your own experiences, 
                    and help others discover hidden destinations.      
                    </Typography>
                    <List
                    sx = {{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                    display: 'list-item',
                    }, marginLeft: "5%"
                    }}>
                        <ListItem> Save only hidden locations from cities visited to the custom trip folders </ListItem>
                        <ListItem> Share your experiences at the locations in the activity feed </ListItem>
                    </List>
                    <Link to='#signup' style={{textDecoration: 'None'}} smooth>
                        <Button onClick="" variant="contained" sx={{background: 'black', marginTop: "3%"}} size="large" > Download </Button>
                    </Link>
                </div>
            </ThemeProvider>
        </div>
    );
}

export { ShareScreen };
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyC6V7XYqAD1I0-Fh0pEiYM-qwawzxDk63Y",
  authDomain: "hidden-website-569f2.firebaseapp.com",
  projectId: "hidden-website-569f2",
  storageBucket: "hidden-website-569f2.appspot.com",
  messagingSenderId: "515348619724",
  appId: "1:515348619724:web:4f592785b0192e58e03d69",
  measurementId: "G-ZM92GJ0FH0"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore();

export {app, db}
import { React } from 'react';
import './IntroSection.css';
import gif from './collage.gif'
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { HashLink as Link } from 'react-router-hash-link';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

theme.typography.h2 = {
   fontSize: "60px",
  '@media (max-width:450px)': {
    fontSize: "35px",
  },
};

theme.typography.h4 = {
    fontSize: "30px",
    '@media (max-width:450px)': {
      fontSize: "22px",
    },
}

const IntroSection = () => {
    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ bgcolor: 'background.paper', pt: 8, pb: 6,}} >
                <Container maxWidth="l">
                    <Typography className='catch-phrase' variant="h2" align="center" gutterBottom > The Best Spots Are No Longer Secret </Typography>
                    <div className='intro-text' align="center">
                        <Typography className='statement' variant="h4" align="center" color="text.primary" paragraph >
                        Unlock secret spots recommended by locals and meet new people while traveling!
                        </Typography>
                        <Link to='#signup' style={{textDecoration: 'None'}} smooth>
                            <Button onClick={null} variant="contained" sx={{background: 'black'}} size="large" >Become an Early Member-{'>'}</Button>
                        </Link>
                    </div>
                    <img src={gif} className='welcome-gif' alt="homepage gif" />
                </Container>
            </Box>
        </ThemeProvider>
    );

}

export { IntroSection };
import { React } from 'react';
import './FindSection.css';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { HashLink as Link } from 'react-router-hash-link';
import appscreen1 from './StartupImages/homescreen.png';
import appscreen2 from './StartupImages/detailsscreen.png';
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme();

theme.typography.h3 = {
    fontSize: "45px",
   '@media (max-width:450px)': {
     fontSize: "30px",
   },
 };


const FindSection = () => {
    return (
        <div className='find' id="find">
            <ThemeProvider theme={theme}>
                <h1 className='section-title'>What We Offer</h1>
                <div className='find-screen'>
                    <img src={appscreen1} className='screens' alt='screen1' />
                    <img src={appscreen2} className='screens' alt='screen2' />
                </div>
                <div className='find-text'>
                    <Typography sx={{marginTop:"10%"}} variant="h4" > Find </Typography>
                    <Typography gutterBottom variant="h3" > Authentic Experiences </Typography>
                    <hr width="8%" className='line' align="left"/>
                    <Typography gutterBottom variant="h7" > 
                    Get insider knowledge and local recommendations to enhance your travel experience. 
                    Find hidden cafes, secluded beaches, secret street art, and much more with ease.        
                    </Typography>
                    <List
                    sx = {{
                    listStyleType: 'disc',
                    pl: 2,
                    '& .MuiListItem-root': {
                        display: 'list-item',
                    }, marginLeft: "5%"
                    }}>
                        <ListItem> Use the map to find hidden locations shared by our community </ListItem>
                        <ListItem> Have access to our local business partners with exclusive benefits </ListItem>
                        <ListItem> Find reviews to hidden locations from friends/community members </ListItem>
                    </List>
                    <Link to='#signup' style={{textDecoration: 'None'}} smooth>
                        <Button onClick="" variant="contained" sx={{background: 'black', marginTop: "3%"}} size="large" > Download </Button>
                    </Link>
                </div>
            </ThemeProvider>
        </div>
    );
}

export { FindSection };
import * as React from 'react';
import { useState } from 'react';
import './App.css';
import { db } from "./firebase"
import { addDoc, collection } from '@firebase/firestore';
import { BrowserRouter } from 'react-router-dom';
import { HashLink as Link } from 'react-router-hash-link';
import logo from './hidden_logo.png';
import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Alert from '@mui/material/Alert';
import { IntroSection } from './IntroSection.js';
import { MiddleSection } from './MiddleSection.js';
import { FindSection } from './FindSection.js';
import { ConnectSection } from './ConnectSection';
import { ShareScreen } from './ShareSection';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="left">
      {'Copyright © '}
      <Link color="inherit" style={{textDecoration: 'None'}} >
        Hidden
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const theme = createTheme();

theme.typography.h2 = {
  fontSize: "55px",
 '@media (max-width:450px)': {
   fontSize: "35px",
 },
};

theme.typography.h5 = {
  fontSize: "20px",
 '@media (max-width:450px)': {
   fontSize: "18px",
 },
};


function App() {
  const [successMessage, setSuccessMessage] = useState(false);

  const saveEmail = (event) => {
    event.preventDefault();

    const email = event.target.user_email.value;

    addDoc(collection(db, "emails"), {
      email: email
    });
    event.target.user_email.value = "";
    setSuccessMessage(true);
  }
  
  return (
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <AppBar position="relative" sx={{
            bgcolor: 'background.paper',
          }}>
        <Toolbar sx={{ flexWrap: 'wrap' }}>
          <img src={logo} className='img-logo' nowrap alt="Hidn Logo" />
          <Typography noWrap sx={{ flexGrow: 1 }}></Typography>
          <Link to='#signup' style={{textDecoration: 'None'}} smooth>
            <Button onClick="" variant="contained" className='button-changes' sx={{background: 'black'}}>Join Waitlist-{'>'}</Button>
          </Link>
        </Toolbar>
      </AppBar>
      <main>
        <IntroSection />
        <hr className='line' width="90%"/>
        <MiddleSection />
        <Container>
          <FindSection />
          <ConnectSection />
          <ShareScreen />
        </Container>
        <hr className='line' width="90%"/>
        <div id='signup'>
          <Container align="center">
            <img src={logo} className='img-logo' alt="Hidn Logo" />
            <Typography sx={{marginTop:"5%"}} variant="h2" > Unlock your Travels </Typography>
            <Typography sx={{marginTop:"1%"}} variant="h5" > We are almost ready to release! </Typography>
            <Typography sx={{marginBottom:"3%"}} variant="h5" > Join our waitlist and be the first one to be notified! </Typography>
            <form onSubmit={saveEmail} style={{marginBottom: "10%"}}>
              <input className='emailinp' type="email" name="user_email" />
              <Button type='submit' variant="contained" sx={{background: 'black'}} size="large" >Submit</Button>
            </form>
            {successMessage ? 
              <Alert onClose={() => { setSuccessMessage(false); }}>Thank you for signing up to our waitlist!</Alert>
            : null}
          </Container>
        </div>
      </main>
      {/* Footer */}
      <Box sx={{ bgcolor: 'background.paper', p: 6 }} component="footer">
        <Copyright />
      </Box>
      {/* End footer */}
    </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
